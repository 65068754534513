<template lang="pug">
.error-container(:class="{relative:relative}")
  .error(v-for="e in errors" :class="{success: e.tag === 'success'}")
    | {{e.msg}}
    b-icon(icon="x" @click="removeCurrent(e)")
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props:{
    relative: Boolean
  },
  methods:{
    ...mapActions(["RemovePageError"]),
    removeCurrent(e){
      this.RemovePageError(e.id)
    },
    setupTimer() {
      setTimeout(() => {
        if (this.errors.length > 0) {
          this.removeCurrent(this.errors[0]);
          this.setupTimer();
        }
      }, 5000); // Remove the first error after 5 seconds
    },
  },
  computed: {
    errors(){
      if (this.$store.state.pageErrors.length > 0) {
        this.setupTimer();
      }
      return this.$store.state.pageErrors;
    }
  },
  mounted() {
    
  },
}

</script>

<style scoped lang="scss">
.error-container{
  position: fixed; 
  z-index: 999;
  top: 20px;
  right: 40px;
  width: 450px;
  >.error{
    background-color:$danger;
    width: 100%;
    font-size: 20;
    border-radius: 30px;
    min-height: 40px;
    color: white;
    display: flex;
    text-align: right;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    box-shadow: -2px -2px 5px 3px rgba(0,0,0,0.2);
    position: relative;
    transition: height 1s ease;
    padding: 5px 20px 5px 40px;
    z-index: 999;
    >.b-icon{
      position: absolute;
      left: 10px;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      &:hover{
        font-size: 25px;
        right:7.5px;
        transition: all 0.2s ease;
      }
    }
    &.success{
      background-color: $success !important;
    }
  }
}
</style>