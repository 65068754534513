<template lang="pug">
.page-container
    .header-container
      h1.pageTitle {{title}}
      slot(name="optionalElement")
    slot

</template>

  
<script>
import { Capacitor } from '@capacitor/core';

export default {
  props:{
    title: String
  },
  mounted() {
    if (Capacitor.isNativePlatform()) {
      this.addMobileAppClass();
    }
  },
  methods: {
    addMobileAppClass() {
      document.body.classList.add('mobile-app');
    },
  },
}

</script>

<style scoped lang="scss">
.page-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 0px;
  box-sizing: border-box;
}
/* Styles specific to mobile devices */
@media (max-width: 850px) { 
  body.mobile-app .page-container { /* Styles specific to the mobile app and devices under 850px */
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 75px;
    box-sizing: border-box;
  }
}


.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: $medium-Font-Size;
}


</style>