<template lang="pug">
.pageBlock
  .cta-button
    slot(name="cta")
  .blockTitle {{title}}
  .undertext
    slot(name="undertext")
  slot

</template>
  
<script>
export default {
  props:{
    title: String
  }
}

</script>

<style scoped lang="scss">
.pageBlock{
  width:100%;
  position: relative;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.08);
  >.cta-button{
    float: right;
    margin: 0px 0px;
  }
  >.undertext{
    font-size: 14px;
    font-weight: 250;
    color:#78849e;
  }
}

</style>