<template>
  <div class="loader" :style="{alignItems: position == 'top' ? 'flex-start' : 'center', height: height ? height+'px' : '100%', flexDirection: align == 'row' ? 'row' :'column'}" :class="{small: small, large: large}">
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span v-if="text != null" class="loader-text" v-html="text"></span>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props:{
    position: String,
    align: String,
    height: Number,
    small: Boolean,
    large: Boolean,
    color: String,
    text: String,
  }
}
</script>
<style lang="scss" scoped>
  .loader{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    flex-direction: column;

    .loader-text {
      font-size: 16px; /* Adjust this to change the font size */
      margin-left: 10px;
      color: $text-color;
    }

    .spinner {
      display: inline-block;
      position: relative;
      width: 60px;
      height: 60px;

      div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 48px;
        height: 48px;
        margin: 6px;
        border: 6px solid #6916a0;
        border-radius: 50%;
        animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $xProfitPurpl transparent transparent transparent;
      }
      div:nth-child(1) {
        animation-delay: -0.45s;
      }
      div:nth-child(2) {
        animation-delay: -0.3s;
      }
      div:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader.small .spinner {
    width: 40px;
    height: 40px;
    
    div {
      width: 32px;
      height: 32px;
      border-width: 4px;
    }
  }

  .loader.large .spinner {
    width: 120px;
    height: 120px;
    
    div {
      width: 96px;
      height: 96px;
      border-width: 12px;
    }
  }
</style>
