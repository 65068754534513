<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15pt" height="28pt" viewBox="20 40 992 992"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1032.000000) scale(0.100000,-0.100000)"
fill="#1D2752" stroke="none">
<path d="M5080 10300 c0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
-20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20
0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -65 -20 -58 0 -65 -2
-65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53
0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
-20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20
0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -60 -20 -57 0 -60 -1 -60 -25 0 -24 -2 -25 -65 -25 -58
0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
-20 -60 -20 -53 0 -60 -2 -60 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20
0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
-20 -20 0 -13 -7 -20 -20 -20 -20 0 -20 -7 -20 -1795 l0 -1795 -40 0 c-33 0
-40 -3 -40 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
-20 -65 -20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20
0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53
0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7
-20 -40 -20 -33 0 -40 -3 -40 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20
0 -17 -7 -20 -45 -20 -38 0 -45 -3 -45 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7 -20 -40 -20 -33
0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20
-20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -20 0 -20 -7 -20 -1955 0
-1948 0 -1955 20 -1955 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20
-20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 65 -20 63 0 65 -1 65
-25 0 -24 3 -25 60 -25 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 65 -20 58 0 65 -2 65
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
-20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
-20 0 -18 7 -20 80 -20 73 0 80 2 80 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18
7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 65 20
58 0 65 2 65 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2
60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18
7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20
53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2
60 20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18
7 20 60 20 57 0 60 1 60 25 0 24 3 25 60 25 53 0 60 2 60 20 0 18 7 20 60 20
53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 -3
40 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 57 0 60 -1 60 -25 0 -24 3 -25 60 -25 53 0 60 -2
60 -20 0 -18 7 -20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 65 -20 58 0 65 -2
65 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 80 -20 73 0 80 2 80
20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7
20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53
0 60 2 60 20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 53 0 60 2 60
20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7
20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53
0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60
20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 57 0 60 1 60 25 0 24 2
25 65 25 58 0 65 2 65 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53
0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40
20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
20 20 20 20 0 20 7 20 1955 0 1948 0 1955 -20 1955 -17 0 -20 7 -20 40 0 33
-3 40 -20 40 -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17
-7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 l-60 0 0 1795 c0 1788 0 1795 -20 1795 -13 0 -20 7 -20 20 0 13
-7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17
-7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -65 20 -63 0 -65 1 -65 25 0 24
-3 25 -60 25 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
-7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -80 20 -73 0 -80 -2 -80 -20z
m120 -605 c0 -24 3 -25 60 -25 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60
-2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60
-2 60 -20 0 -18 7 -20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20 60 -20 53 0 60
-2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60
-2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60
-2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40
-3 40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -18 -7 -20 -60 -20
-53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20
-60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0
-18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -65 -20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 -53
0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -17 -7
-20 -40 -20 -33 0 -40 3 -40 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7
20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7
20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7
20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7
20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7
20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7
20 -40 20 -33 0 -40 3 -40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 18 7 20 60
20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60
2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0
18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60
20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60
2 60 20 0 18 7 20 60 20 57 0 60 1 60 25 0 23 4 25 40 25 36 0 40 -2 40 -25z
m-2060 -925 c0 -18 7 -20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20 60 -20 53 0
60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0
60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0
60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0
60 -2 60 -20 0 -18 7 -20 60 -20 57 0 60 -1 60 -25 0 -24 3 -25 60 -25 53 0
60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 65 -20 58 0
65 -2 65 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0
60 -2 60 -20 0 -13 7 -20 20 -20 20 0 20 -7 20 -1490 l0 -1490 -80 0 c-73 0
-80 2 -80 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -65 20 -58 0
-65 2 -65 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0
-60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0
-60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0
-60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0
-60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0
-60 2 -60 20 0 18 -7 20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20 -60 20 l-60 0
0 1515 0 1515 60 0 c53 0 60 -2 60 -20z m4160 -1495 l0 -1515 -40 0 c-33 0
-40 -3 -40 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -65
-20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
-20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20
0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53
0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -85 -20 -78 0 -85 -2 -85 -20 0 -17 -7
-20 -40 -20 -33 0 -40 -3 -40 -20 0 -19 -7 -20 -100 -20 l-100 0 0 1490 c0
1483 0 1490 20 1490 13 0 20 7 20 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7
20 60 20 53 0 60 2 60 20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 53
0 60 2 60 20 0 18 7 20 60 20 57 0 60 1 60 25 0 24 3 25 60 25 53 0 60 2 60
20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7
20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53
0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60
20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 l60 0 0 -1515z m-4440
-2055 c0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 80 -20 73 0 80 -2
80 -20 0 -18 7 -20 65 -20 58 0 65 -2 65 -20 0 -17 7 -20 40 -20 33 0 40 -3
40 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2
60 -20 0 -18 7 -20 80 -20 l80 0 0 -40 0 -40 -60 0 c-53 0 -60 -2 -60 -20 0
-18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -57
0 -60 -1 -60 -25 0 -24 -3 -25 -60 -25 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
-20 -65 -20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20
0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -19 -7 -20 -100 -20 -93 0 -100
1 -100 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60
2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2
-60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -57 0 -60 1
-60 25 0 24 -2 25 -65 25 -58 0 -65 2 -65 20 0 18 -7 20 -60 20 -53 0 -60 2
-60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2
-60 20 0 18 -7 20 -60 20 l-60 0 0 40 0 40 60 0 c53 0 60 2 60 20 0 18 7 20
60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0
60 2 60 20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 53 0 60 2 60 20
0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20
60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0
60 2 60 20 0 19 7 20 100 20 93 0 100 -1 100 -20z m4840 0 c0 -17 7 -20 40
-20 33 0 40 -3 40 -20 0 -18 7 -20 80 -20 73 0 80 -2 80 -20 0 -18 7 -20 60
-20 53 0 60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 60
-20 53 0 60 -2 60 -20 0 -18 7 -20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20 60
-20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60
-20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 80
-20 l80 0 0 -40 0 -40 -60 0 c-53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53
0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -57 0 -60 -1 -60 -25 0 -24 -2
-25 -65 -25 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20
0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7 -20 -40 -20 -33
0 -40 -3 -40 -20 0 -19 -7 -20 -100 -20 -93 0 -100 1 -100 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -57 0 -60 1 -60 25 0 24 -3 25 -60
25 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60
20 l-60 0 0 40 0 40 60 0 c53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0
18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60
20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60
2 60 20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 53 0 60 2 60 20 0
18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 19 7 20 100
20 93 0 100 -1 100 -20z m-6890 -930 c0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0
-18 7 -20 80 -20 73 0 80 -2 80 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
-18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
-18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
-18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
-18 7 -20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
-18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
-17 7 -20 40 -20 l40 0 0 -1510 0 -1510 -60 0 c-53 0 -60 2 -60 20 0 18 -7 20
-60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20
-60 20 -53 0 -60 2 -60 20 0 18 -7 20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20
-60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20
-60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20
-60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20
-60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20
-60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 15 -7 20
-25 20 l-25 0 0 1490 0 1490 105 0 c98 0 105 -1 105 -20z m4070 -1470 c0
-1483 0 -1490 -20 -1490 -13 0 -20 -7 -20 -20 0 -18 -7 -20 -60 -20 -53 0 -60
-2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -65 -20
-58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20
-60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0
-18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53
0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
-20 -65 -20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 l-60 0 0 1510 0 1510
60 0 c53 0 60 2 60 20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 53 0
60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20
0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20
60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0
60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20
0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20
80 20 l80 0 0 -1490z m760 1470 c0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18
7 -20 85 -20 78 0 85 -2 85 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
-20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
-20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
-20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
-20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
-20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -17 7
-20 40 -20 l40 0 0 -1510 0 -1510 -60 0 c-53 0 -60 2 -60 20 0 18 -7 20 -65
20 -58 0 -65 2 -65 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20 -60
20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 13 -7 20 -20
20 -20 0 -20 7 -20 1490 l0 1490 100 0 c93 0 100 -1 100 -20z m4080 -1470 c0
-1483 0 -1490 -20 -1490 -13 0 -20 -7 -20 -20 0 -18 -7 -20 -65 -20 -58 0 -65
-2 -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20
-53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20
-60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0
-18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2
-60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53
0 -60 -2 -60 -20 0 -18 -7 -20 -65 -20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60
-20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
-20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 l-60 0 0 1510 0 1510
60 0 c53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0
60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 65 20 58 0 65 2 65 20
0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20
60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0
60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20
0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20
85 20 l85 0 0 -1490z"/>
   
</g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'CubeIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}

.icon-wrapper svg {
  padding: 0;
  margin: 0;
  display: block;
}
.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  