<template lang="pug">
b-modal#ChooseAdAccountOnboarding(centered hide-footer hide-header button-size="sm" no-close-on-backdrop)
    .modalContent
        .close(@click="hideModal")
            span close
        .title
            span.black Select Ad Account
        .textual.thin Please choose the Ad Account you use for this store
        .Ad-Accounts-Container
            LocalLoader(v-if="loading")
            .input-container(v-else v-for="(ad, i) in adAccounts" @click="onAdSelected(ad)") 
                .textual.thin {{ad.id}}
        
</template>
    <script>
    
    import { mapActions } from 'vuex'
    export default {
      components: {
    
      },
      data() {
        return {
          adAccounts: [],
          selectedAccount: "",
          platform: "",
          loading: false,
          mode: "general"
        }
      },
      computed: {
        computedDisabledDates() {
    
    
        }
      },
      methods: {
        ...mapActions(["AddPageError"]),
        launchModal(adAccounts, platform, mode) {
    
          this.adAccounts = adAccounts;
          this.platform = platform
          this.mode = mode
    
    
          this.$bvModal.show('ChooseAdAccountOnboarding');
        },
    
    
        hideModal() {
    
          this.loading = false;
          this.$emit('closedAdAccountOnboarding', true);
          this.$bvModal.hide('ChooseAdAccountOnboarding');
    
        },
        onAdSelected(account) {
    
          this.loading = true;
          console.log(account)
          console.log(this.loading)
    
          if (account.id != null) {
            console.log("Ready to update Selection")
    
            if (this.platform == "pinterest") {
    
              var adAccount = {
                adAccountID: account.id,
                currency: account?.currency ?? "",
                mode: this.mode,
              }
    
              this.$store.dispatch("userdata/SelectPinterestAdAccount", adAccount)
                .then(data => {
                  console.log("Ad Accounts updated", data)
                  if (data == "success") {
    
                    this.$emit('adAccountSelected', true);
                    this.hideModal()
    
                  } else {
                    this.AddPageError({ msg: "Pinterest Connection Failed - Please Retry Again", tag: "error" });
    
                    this.$emit('adAccountSelected', false);
                    this.hideModal()
                  }
                }).catch(e => {
                  this.AddPageError({ msg: "Requesting Pinterest failed", tag: "error" });
                  this.$emit('adAccountSelected', false);
                  this.hideModal()
                });
            } else if (this.platform == "facebook") {
    
              console.log("Ready to update Selection")
    
              var adAccount = {
                adAccountID: account.id,
                adAccountID2: account.account_id,
                mode: this.mode,
              }
    
              console.log(adAccount)
              this.$store.dispatch("userdata/SelectFacebookAdAccount", adAccount)
                .then(data => {
                  console.log("Ad Accounts updated", data)
                  if (data == "success") {
    
                    this.$emit('adAccountSelected', true);
                    this.hideModal()
    
                  } else {
                    this.AddPageError({ msg: "Facebook Connection Failed - Please Retry Again", tag: "error" });
    
                    this.$emit('adAccountSelected', false);
                    this.hideModal()
                  }
                }).catch(e => {
                  this.AddPageError({ msg: "Requesting Pinterest failed", tag: "error" });
                  this.$emit('adAccountSelected', false);
                  this.hideModal()
                });
    
            } else if (this.platform == "snapchat") {
    
              var adAccount = {
                adAccountID: account.id2,
                adAccountname: account.id,
                currency: account.currency,
                timezone: account.timezone,
                mode: this.mode,
              }
    
              console.log(adAccount)
              this.$store.dispatch("userdata/SelectSnapchatAdAccount", adAccount)
                .then(data => {
                  console.log("Ad Accounts updated", data)
                  if (data == "success") {
    
                    this.$emit('adAccountSelected', true);
                    this.hideModal()
    
                  } else {
                    this.AddPageError({ msg: "SnpachatConnection Failed - Please Retry Again", tag: "error" });
    
                    this.$emit('adAccountSelected', false);
                    this.hideModal()
                  }
                }).catch(e => {
                  this.AddPageError({ msg: "Requesting Pinterest failed", tag: "error" });
                  this.$emit('adAccountSelected', false);
                  this.hideModal()
                });
    
            } else if (this.platform == "google") {
    
              var adAccount = {
                adAccountID: account.id,
                mode: this.mode,
              }
    
              console.log(adAccount)
              this.$store.dispatch("userdata/SelectGoogleAdAccount", adAccount)
                .then(data => {
                  console.log("Ad Accounts updated", data)
                  if (data == "success") {
    
                    this.$emit('adAccountSelected', true);
                    this.hideModal()
    
                  } else {
                    this.AddPageError({ msg: "SnpachatConnection Failed - Please Retry Again", tag: "error" });
    
                    this.$emit('adAccountSelected', false);
                    this.hideModal()
                  }
                }).catch(e => {
                  this.AddPageError({ msg: "Requesting Google failed", tag: "error" });
                  this.$emit('adAccountSelected', false);
                  this.hideModal()
                });
    
            } else if (this.platform == "tiktok") {
    
              var adAccount = {
                adAccountID: account.id,
                mode: this.mode,
              }
    
              console.log(adAccount)
              this.$store.dispatch("userdata/SelectTiktokAdAccount", adAccount)
                .then(data => {
                  console.log("Ad Accounts updated", data)
                  if (data == "success") {
    
                    this.$emit('adAccountSelected', true);
                    this.hideModal()
    
                  } else {
                    this.AddPageError({ msg: "Connecting TikTok Failed - Please Retry Again", tag: "error" });
    
                    this.$emit('adAccountSelected', false);
                    this.hideModal()
                  }
                }).catch(e => {
                  this.AddPageError({ msg: "Requesting Google failed", tag: "error" });
                  this.$emit('adAccountSelected', false);
                  this.hideModal()
                });
    
            }
          }
    
        },
    
    
      }
    
    }
    </script>
    <style lang="scss" scoped>
    #ChooseAdAccountOnboarding {
    
      .modalContent {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        padding: 10px;
      }
    
      .title {
        .black {
          color: $text-color;
        }
      }
    
      .textual {
        font-size: 14px;
        padding-right: 50px;
      }
    
    
      .Ad-Accounts-Container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: $layoutDefaultMargin;
        overflow: auto;
        max-height: 300px;
      }
    
      .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        cursor: pointer;
    
      }
    
      .input-wrapper {
        border: none; // added to remove the default border
        width: 100%; //
        margin-top: 5px;
        margin-bottom: 5px;
      }
    
    
    
      button.button-right {
        margin-left: 10px;
        height: 30px;
        width: 100px;
        background-color: $text-color;
        color: white;
        border-radius: 5px;
        border: none;
        margin-left: auto;
    
      }
    
      .close {
        position: absolute;
        right: 20px;
        color: lightgrey;
        cursor: pointer;
        font-weight: 100;
        top: 20px;
    
        &:hover {
          color: black
        }
      }
    
    
    }
    </style>