<template lang="pug">
.info-banner(@click="$router.push('/settings/general');")
    span.icon ⚠️
    span.text {{ text}}
</template>
  
<script>
import moment from "moment";

export default {
    props: {
        text: String,
    },
    data() {
        return {
            isOpen: false,

        };
    },
    methods: {

    },
    mounted() {

    },
    beforeDestroy() {

    }

};
</script>

<style lang="scss" scoped>
.info-banner {
    display: flex;
    align-items: center;
    background-color: $danger;
    color: white;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
}
.info-banner:hover {
  cursor: pointer;
  /* Add any other visual feedback for hover state */
}
.icon {
    margin-right: 15px;
}

.text {
    font-weight: $normal-Font-Weight;
    font-size: $medium-Font-Size;
}
</style>