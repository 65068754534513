<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="15pt" height="18pt" viewBox="40 40 992 992"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1032.000000) scale(0.100000,-0.100000)" fill="#1D2752" stroke="none">
                <path d="M4190 10300 c0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7
    -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20
    0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -18 0 -20 -7 -20 -85 0 -78 -2 -85 -20 -85 -19 0 -20 -7 -20 -100
    0 -93 -1 -100 -20 -100 -19 0 -20 -7 -20 -120 0 -113 -1 -120 -20 -120 -19 0
    -20 -7 -20 -100 0 -93 -1 -100 -20 -100 -17 0 -20 -7 -20 -40 0 -33 -3 -40
    -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0
    -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -15 -7 -20 -25 -20 -18 0 -25 -5 -25 -20 0 -17 -7 -20 -40 -20 -33
    0 -40 -3 -40 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -19 -7 -20
    -160 -20 -153 0 -160 1 -160 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7
    20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7
    20 -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7
    20 -80 20 -73 0 -80 2 -80 20 0 19 -7 20 -165 20 -158 0 -165 -1 -165 -20 0
    -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3
    -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33
    0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -45
    0 -38 -3 -45 -20 -45 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7
    -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17
    0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20
    -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40
    0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7
    -20 -20 0 -15 -7 -20 -25 -20 -22 0 -25 -4 -25 -40 0 -33 -3 -40 -20 -40 -17
    0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20
    0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -45 0 -38 -3 -45 -20 -45 -17 0 -20 -7
    -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -18
    0 -20 -7 -20 -80 0 -73 -2 -80 -20 -80 -19 0 -20 -7 -20 -140 0 -133 1 -140
    20 -140 18 0 20 -7 20 -60 0 -53 2 -60 20 -60 18 0 20 -7 20 -60 0 -53 2 -60
    20 -60 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40
    20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -15 7 -20
    25 -20 20 0 25 -5 25 -25 0 -18 5 -25 20 -25 13 0 20 -7 20 -20 0 -17 7 -20
    40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40
    20 -40 19 0 20 -7 20 -100 0 -93 1 -100 20 -100 17 0 20 -7 20 -40 0 -33 -3
    -40 -20 -40 -19 0 -20 -7 -20 -100 0 -93 -1 -100 -20 -100 -17 0 -20 -7 -20
    -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0
    -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20
    0 -17 -7 -20 -40 -20 -36 0 -40 -2 -40 -25 0 -18 -5 -25 -20 -25 -13 0 -20 -7
    -20 -20 0 -15 -7 -20 -25 -20 -18 0 -25 -5 -25 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40
    -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40
    0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -18 0 -20 -7
    -20 -60 0 -53 -2 -60 -20 -60 -18 0 -20 -7 -20 -60 0 -53 -2 -60 -20 -60 -19
    0 -20 -7 -20 -140 0 -133 1 -140 20 -140 18 0 20 -7 20 -80 0 -73 2 -80 20
    -80 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 17 0 20 -7 20 -45 0 -38 3 -45 20 -45 13 0 20 -7 20 -20 0 -13 7 -20 20
    -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20
    -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -36 3 -40 25
    -40 18 0 25 -5 25 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 17 0 20 -7 20 -45 0 -38 3 -45 20 -45 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20
    -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20
    -20 13 0 20 -7 20 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60
    -20 53 0 60 -2 60 -20 0 -19 7 -20 165 -20 158 0 165 1 165 20 0 18 7 20 80
    20 73 0 80 2 80 20 0 17 7 20 40 20 33 0 40 3 40 20 0 18 7 20 60 20 53 0 60
    2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0
    18 7 20 60 20 53 0 60 2 60 20 0 19 7 20 160 20 153 0 160 -1 160 -20 0 -18 7
    -20 60 -20 53 0 60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -15 7
    -20 25 -20 18 0 25 -5 25 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 19 0 20 -7 20 -100 0 -93 1
    -100 20 -100 19 0 20 -7 20 -120 0 -113 1 -120 20 -120 19 0 20 -7 20 -100 0
    -93 1 -100 20 -100 18 0 20 -7 20 -85 0 -78 2 -85 20 -85 17 0 20 -7 20 -40 0
    -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0
    -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0
    -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0
    -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0
    -18 7 -20 80 -20 73 0 80 -2 80 -20 0 -20 7 -20 970 -20 963 0 970 0 970 20 0
    18 7 20 80 20 73 0 80 2 80 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40
    20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20 33 0 40
    3 40 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0
    13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20
    40 18 0 20 7 20 85 0 78 2 85 20 85 19 0 20 7 20 100 0 93 1 100 20 100 19 0
    20 7 20 140 0 133 1 140 20 140 18 0 20 7 20 80 0 73 2 80 20 80 17 0 20 7 20
    40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 15 7 20 25 20 18 0 25 5 25 20 0 13 7 20 20 20 13
    0 20 7 20 20 0 17 7 20 40 20 33 0 40 3 40 20 0 18 7 20 60 20 53 0 60 2 60
    20 0 19 7 20 160 20 153 0 160 -1 160 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
    -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 60 -20 53 0 60 -2 60
    -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40 -3 40
    -20 0 -18 7 -20 80 -20 73 0 80 -2 80 -20 0 -19 7 -20 165 -20 158 0 165 1
    165 20 0 18 7 20 80 20 73 0 80 2 80 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13
    7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20
    13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7
    20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33
    3 40 20 40 17 0 20 7 20 45 0 38 3 45 20 45 17 0 20 7 20 40 0 33 3 40 20 40
    13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7
    20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13
    7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40
    13 0 20 7 20 20 0 15 7 20 25 20 23 0 25 4 25 40 0 33 3 40 20 40 17 0 20 7
    20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13
    7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40
    17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7
    20 45 0 38 3 45 20 45 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33
    3 40 20 40 18 0 20 7 20 80 0 73 2 80 20 80 19 0 20 7 20 140 0 133 -1 140
    -20 140 -18 0 -20 7 -20 60 0 53 -2 60 -20 60 -18 0 -20 7 -20 60 0 53 -2 60
    -20 60 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40
    -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 15 -7 20 -25 20 -20 0 -25 5 -25 25 0 23 -4 25
    -40 25 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40
    -20 40 -19 0 -20 7 -20 100 0 93 -1 100 -20 100 -17 0 -20 7 -20 40 0 33 3 40
    20 40 19 0 20 7 20 100 0 93 1 100 20 100 17 0 20 7 20 40 0 33 3 40 20 40 13
    0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20
    20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20 33
    0 40 3 40 20 0 15 7 20 25 20 20 0 25 5 25 25 0 18 5 25 20 25 13 0 20 7 20
    20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7
    20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13
    0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20
    20 0 13 7 20 20 20 18 0 20 7 20 60 0 53 2 60 20 60 18 0 20 7 20 60 0 53 2
    60 20 60 19 0 20 7 20 140 0 133 -1 140 -20 140 -18 0 -20 7 -20 80 0 73 -2
    80 -20 80 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -17 0 -20 7 -20 45 0 38 -3 45 -20 45 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -17 0 -20 7 -20 40 0 36 -2 40 -25 40 -18 0 -25 5 -25 20 0 13 -7
    20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 45 0 38 -3
    45 -20 45 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7
    20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7
    20 -80 20 -73 0 -80 2 -80 20 0 19 -7 20 -165 20 -158 0 -165 -1 -165 -20 0
    -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3
    -40 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53
    0 -60 -2 -60 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -18 -7 -20 -60
    -20 -53 0 -60 -2 -60 -20 0 -19 -7 -20 -160 -20 -153 0 -160 1 -160 20 0 18
    -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13
    -7 20 -20 20 -13 0 -20 7 -20 20 0 15 -7 20 -25 20 -18 0 -25 5 -25 20 0 13
    -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33
    -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -18 0 -20 7 -20 80 0 73
    -2 80 -20 80 -19 0 -20 7 -20 140 0 133 -1 140 -20 140 -19 0 -20 7 -20 100 0
    93 -1 100 -20 100 -18 0 -20 7 -20 85 0 78 -2 85 -20 85 -17 0 -20 7 -20 40 0
    33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 l0
    40 -40 0 c-33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7
    20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7
    20 -80 20 -73 0 -80 2 -80 20 0 20 -7 20 -970 20 -963 0 -970 0 -970 -20z
    m1820 -830 c0 -33 3 -40 20 -40 19 0 20 -7 20 -120 0 -113 1 -120 20 -120 19
    0 20 -7 20 -100 0 -93 1 -100 20 -100 19 0 20 -7 20 -100 0 -93 1 -100 20
    -100 18 0 20 -7 20 -60 0 -53 2 -60 20 -60 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20
    -40 15 0 20 -7 20 -25 0 -18 5 -25 20 -25 13 0 20 -7 20 -20 0 -13 7 -20 20
    -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20
    -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20
    -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20
    -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20
    -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 60
    -20 53 0 60 -2 60 -20 0 -18 7 -20 65 -20 58 0 65 -2 65 -20 0 -19 7 -20 120
    -20 113 0 120 -1 120 -20 0 -19 7 -20 100 -20 93 0 100 1 100 20 0 19 7 20
    140 20 133 0 140 1 140 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20
    53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0 18 7 20 60 20 53 0 60 2
    60 20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60 20 l60 0 0 -40 c0 -33 3
    -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 15 0 20 -7 20 -25 0 -18 5 -25 20 -25 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 -3
    -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20
    0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20
    0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20
    -40 -15 0 -20 -7 -20 -25 0 -18 -5 -25 -20 -25 -18 0 -20 -7 -20 -60 0 -53 -2
    -60 -20 -60 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -18 0 -20 -7 -20 -80
    0 -73 -2 -80 -20 -80 -20 0 -20 -7 -20 -280 0 -273 0 -280 20 -280 18 0 20 -7
    20 -80 0 -73 2 -80 20 -80 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 18 0 20 -7
    20 -65 0 -58 2 -65 20 -65 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7
    20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7
    20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7
    20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7
    20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7
    20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3
    40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7
    20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3
    40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7
    20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0
    -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -17 0 -20 -7 -20 -45 0 -38 -3 -45 -20 -45 -13 0 -20 -7 -20 -20
    0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7
    -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17
    0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20
    -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40
    0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17
    0 -20 -7 -20 -40 l0 -40 -60 0 c-53 0 -60 2 -60 20 0 18 -7 20 -65 20 -58 0
    -65 2 -65 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0
    -40 3 -40 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0
    -60 2 -60 20 0 19 -7 20 -140 20 -133 0 -140 1 -140 20 0 19 -7 20 -100 20
    -93 0 -100 -1 -100 -20 0 -19 -7 -20 -120 -20 -113 0 -120 -1 -120 -20 0 -18
    -7 -20 -65 -20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60
    -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0
    -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20
    0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7
    -20 -40 0 -33 -3 -40 -20 -40 -15 0 -20 -7 -20 -25 0 -18 -5 -25 -20 -25 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20
    -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -18 0 -20 -7 -20 -60 0 -53 -2 -60 -20 -60 -19 0 -20 -7 -20 -100
    0 -93 -1 -100 -20 -100 -19 0 -20 -7 -20 -100 0 -93 -1 -100 -20 -100 -19 0
    -20 -7 -20 -120 0 -113 -1 -120 -20 -120 -17 0 -20 -7 -20 -40 l0 -40 -850 0
    -850 0 0 40 c0 33 -3 40 -20 40 -19 0 -20 7 -20 120 0 113 -1 120 -20 120 -19
    0 -20 7 -20 100 0 93 -1 100 -20 100 -19 0 -20 7 -20 100 0 93 -1 100 -20 100
    -18 0 -20 7 -20 60 0 53 -2 60 -20 60 -17 0 -20 7 -20 40 0 33 -3 40 -20 40
    -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -15 0 -20 7 -20 25 0 18 -5 25 -20 25
    -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20 -60 20
    -53 0 -60 2 -60 20 0 18 -7 20 -65 20 -58 0 -65 2 -65 20 0 19 -7 20 -120 20
    -113 0 -120 1 -120 20 0 19 -7 20 -100 20 -93 0 -100 -1 -100 -20 0 -19 -7
    -20 -140 -20 -133 0 -140 -1 -140 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60
    -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -17 -7 -20 -40 -20 -33 0
    -40 -3 -40 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -65
    -20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 l-60 0 0 40 c0 33 -3 40 -20
    40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20
    20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20
    40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20
    20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20
    40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20
    20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20
    40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 45 0 38 -3 45 -20
    45 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20
    40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40 -20
    40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 3 40 20 40
    13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20 33 0 40 3
    40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13
    7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20
    13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7
    20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13
    7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40
    13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7
    20 45 0 38 3 45 20 45 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33
    3 40 20 40 19 0 20 7 20 100 0 93 1 100 20 100 19 0 20 7 20 240 0 233 -1 240
    -20 240 -19 0 -20 7 -20 100 0 93 -1 100 -20 100 -17 0 -20 7 -20 40 0 33 -3
    40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 45 0 38 -3
    45 -20 45 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7
    20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7
    20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7
    20 -20 20 -17 0 -20 7 -20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20
    40 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20
    7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0
    33 3 40 20 40 15 0 20 7 20 25 0 18 5 25 20 25 17 0 20 7 20 40 0 33 3 40 20
    40 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20
    7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0
    33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20
    40 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20
    7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 l0
    40 60 0 c53 0 60 -2 60 -20 0 -18 7 -20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20
    60 -20 53 0 60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20
    60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -19 7 -20
    140 -20 133 0 140 -1 140 -20 0 -19 7 -20 100 -20 93 0 100 1 100 20 0 19 7
    20 120 20 113 0 120 1 120 20 0 18 7 20 65 20 58 0 65 2 65 20 0 18 7 20 60
    20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20
    7 20 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0
    17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20
    20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20
    7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20 15 0 20 7 20 25 0
    18 5 25 20 25 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20
    40 17 0 20 7 20 40 0 33 3 40 20 40 18 0 20 7 20 60 0 53 2 60 20 60 19 0 20
    7 20 100 0 93 1 100 20 100 19 0 20 7 20 100 0 93 1 100 20 100 19 0 20 7 20
    120 0 113 1 120 20 120 17 0 20 7 20 40 l0 40 850 0 850 0 0 -40z" />
                <path d="M4880 7115 l0 -25 -100 0 c-93 0 -100 -1 -100 -20 0 -18 -7 -20 -80
    -20 -73 0 -80 -2 -80 -20 0 -17 -7 -20 -45 -20 -38 0 -45 -3 -45 -20 0 -18 -7
    -20 -60 -20 -53 0 -60 -2 -60 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20
    0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40
    -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20
    0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40
    0 -33 -3 -40 -20 -40 -18 0 -20 -7 -20 -60 0 -53 -2 -60 -20 -60 -17 0 -20 -7
    -20 -45 0 -38 -3 -45 -20 -45 -18 0 -20 -7 -20 -80 0 -73 -2 -80 -20 -80 -19
    0 -20 -7 -20 -100 l0 -100 -25 0 -25 0 0 -280 0 -280 25 0 25 0 0 -100 c0 -93
    1 -100 20 -100 18 0 20 -7 20 -85 0 -78 2 -85 20 -85 17 0 20 -7 20 -40 0 -33
    3 -40 20 -40 18 0 20 -7 20 -60 0 -53 2 -60 20 -60 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0 -33 3
    -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -17 7
    -20 45 -20 38 0 45 -3 45 -20 0 -18 7 -20 80 -20 79 0 80 0 80 -25 l0 -25 100
    0 c93 0 100 -1 100 -20 0 -20 7 -20 280 -20 273 0 280 0 280 20 0 19 7 20 100
    20 l100 0 0 25 c0 25 1 25 85 25 78 0 85 2 85 20 0 17 7 20 40 20 33 0 40 3
    40 20 0 18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17
    7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20
    33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7
    20 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13
    7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20
    13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7
    20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13
    7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20
    17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 18 0 20 7
    20 60 0 53 2 60 20 60 17 0 20 7 20 40 0 33 3 40 20 40 18 0 20 7 20 85 0 84
    0 85 25 85 l25 0 0 100 c0 93 1 100 20 100 20 0 20 7 20 280 0 273 0 280 -20
    280 -19 0 -20 7 -20 100 l0 100 -25 0 c-25 0 -25 1 -25 80 0 73 -2 80 -20 80
    -17 0 -20 7 -20 45 0 38 -3 45 -20 45 -18 0 -20 7 -20 60 0 53 -2 60 -20 60
    -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20
    -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20
    -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20 -60 20
    -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20 -85 20
    -78 0 -85 2 -85 20 0 19 -7 20 -100 20 l-100 0 0 25 0 25 -280 0 -280 0 0 -25z
    m320 -765 c0 -19 7 -20 140 -20 133 0 140 -1 140 -20 0 -18 7 -20 60 -20 53 0
    60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0
    40 -3 40 -20 0 -15 7 -20 25 -20 18 0 25 -5 25 -20 0 -17 7 -20 40 -20 33 0
    40 -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0
    20 -7 20 -45 0 -38 3 -45 20 -45 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0
    20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 18 0
    20 -7 20 -60 0 -53 2 -60 20 -60 19 0 20 -7 20 -140 0 -133 1 -140 20 -140 17
    0 20 -7 20 -40 0 -33 -3 -40 -20 -40 -19 0 -20 -7 -20 -140 0 -133 -1 -140
    -20 -140 -18 0 -20 -7 -20 -60 0 -53 -2 -60 -20 -60 -17 0 -20 -7 -20 -40 0
    -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -15 0 -20 -7
    -20 -25 0 -18 -5 -25 -20 -25 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20
    0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -15 -7 -20 -25 -20 -18 0 -25 -5
    -25 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33
    0 -40 -3 -40 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -19 -7 -20
    -140 -20 -133 0 -140 -1 -140 -20 0 -17 -7 -20 -40 -20 -33 0 -40 3 -40 20 0
    19 -7 20 -140 20 -133 0 -140 1 -140 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20
    0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -45 20 -38 0 -45 3 -45 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0
    33 -3 40 -20 40 -15 0 -20 7 -20 25 0 18 -5 25 -20 25 -17 0 -20 7 -20 40 0
    33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -18 0 -20 7 -20 60 0
    53 -2 60 -20 60 -19 0 -20 7 -20 140 0 133 -1 140 -20 140 -17 0 -20 7 -20 40
    0 33 3 40 20 40 19 0 20 7 20 140 0 133 1 140 20 140 18 0 20 7 20 60 0 53 2
    60 20 60 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 13
    0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 45 0 38 3 45 20 45 13 0 20 7 20
    20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13
    0 20 7 20 20 0 17 7 20 45 20 38 0 45 3 45 20 0 13 7 20 20 20 13 0 20 7 20
    20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 18 7
    20 60 20 53 0 60 2 60 20 0 19 7 20 140 20 133 0 140 1 140 20 0 17 7 20 40
    20 33 0 40 -3 40 -20z" />


            </g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'SettingsIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
    padding: 0;
    margin: 0;
    display: inline-block;
    position: relative;
}

.icon-wrapper svg {
    padding: 0;
    margin: 0;
    display: block;
}

.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  