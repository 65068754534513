import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import firebase from 'firebase/compat/app';
import { Capacitor } from '@capacitor/core';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/pages/Dashboard.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('../views/pages/Analytics.vue'),
    meta: {
      requiresAuth: true,
      layout: "authenticated"
    }
  },
  {
    path: '/fixedpayments',
    name: 'fixedpayments',
    component: () => import('../views/pages/FixedPayments.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },
  {
    path: '/multi-store-dashboard',
    name: 'multi-store-dashboard',
    component: () => import('../views/pages/MultiStoreDashboard.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },

  
  {
    path: '/settings/general',
    name: 'general',
    component: () => import('../views/pages/setup/General.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },
  {
    path: '/settings/productcosts',
    name: 'productcosts',
    component: () => import('../views/pages/setup/ProductCosts.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },
  {
    path: '/settings/marketing',
    name: 'marketing',
    component: () => import('../views/pages/setup/Marketing.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/pages/Settings.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },
  {
    path: '/redirect.html',
    name: 'redirect',
    component: () => import('../views/userpages/redirect.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },

  // shopify oauth
  {
    path: '/shopify-oauth',
    name: 'oauth',
    component: () => import('../views/shopify/oauth.vue'),
    meta: {
      noCheck: true
    }
  },
  {
    path: '/shopify-oauth-completed',
    name: 'shopify-oauth-completed',
    component: () => import('../views/shopify/completed.vue'),
    meta: {
      noCheck: true
    }
  },

  {
    path: '/admincenter',
    name: 'admincenter',
    component: () => import('../views/userpages/AdminCenter.vue'),
    meta: {
      noCheck: true
    }
  },
  {
    path: '/admincenter/profit-test',
    name: 'admincenter-test',
    component: () => import('../views/userpages/TestStoreLogin.vue'),
    meta: {
      noCheck: true
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/privacy.vue'),
    meta: {
      noCheck: true
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/terms.vue'),
    meta: {
      noCheck: true
    }
  },

  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/pricing.vue'),
    meta: {
      noCheck: true
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/adminView.vue'),
    meta: {
      requiresAuth :true,
      layout: "authenticated"
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: {
      noCheck: true
    }
  },

  // Serve 404 error page
  {
    path: '*',
    name: 'Not Found',
    component: () => import('../views/page404.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.noCheck)
    next();
  else{
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
    if(store != null && currentUser){
      if(store.getters['user/getUser'] != null && Object.keys(store.getters['user/getUser']).length == 0)
      {
        store.dispatch('user/SetUser', currentUser)
      }
      if(store.state.api.shopify_access_code == null){
        store.dispatch("api/initiateApi", {
          access_code : Vue.cookie.get('shopify_accessToken'),
          shop: currentUser.uid
        })
      }
    }
    const isMobileApp = Capacitor.isNativePlatform();

    if(requiresAuth && !currentUser && !isMobileApp) {
      next('/home');
    }

    console.log("loaded windo")
    if(requiresAuth && !currentUser && isMobileApp) {
      console.log("go to admincenter")
      next('/admincenter');
     
    }
    else next()
  }
});

export default router
