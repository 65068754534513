
import firebase from 'firebase/compat/app';
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyA81mRS6NVE0aA3SaHu_ZmxZdcY7ED_jzU",
  authDomain: "arthur-5a26e.firebaseapp.com",
  projectId: "arthur-5a26e",
  storageBucket: "arthur-5a26e.appspot.com",
  messagingSenderId: "733959876014",
  appId: "1:733959876014:web:057847d70188115c7eec3f",
  measurementId: "G-HCWHYV79MQ"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCzOjEMIZaCTLFHDOIGTsAbisE8v2rLkTA",
//   authDomain: "xprofit-test.firebaseapp.com",
//   projectId: "xprofit-test",
//   storageBucket: "xprofit-test.appspot.com",
//   messagingSenderId: "277761633610",
//   appId: "1:277761633610:web:8cd5563b547bea022165b3",
//   measurementId: "G-0S99BNE7XZ"
// }

const firebaseApp = firebase.initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
const perf = getPerformance(firebaseApp);

export { functions, firebaseApp, perf};