<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15pt" height="15pt" viewBox="40 40 992 992"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1032.000000) scale(0.100000,-0.100000)"
fill="#1D2752" stroke="none">
            <path d="M4680 9650 c0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7 -20
    -45 -20 -38 0 -45 -3 -45 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0
    -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40
    0 -33 -3 -40 -20 -40 -18 0 -20 -7 -20 -80 0 -73 -2 -80 -20 -80 -20 0 -20 -7
    -20 -3710 0 -3703 0 -3710 20 -3710 18 0 20 -7 20 -80 0 -73 2 -80 20 -80 17
    0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0
    20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0
    40 -3 40 -20 0 -17 7 -20 45 -20 38 0 45 -3 45 -20 0 -18 7 -20 80 -20 79 0
    80 0 80 -25 l0 -25 480 0 480 0 0 25 c0 25 1 25 85 25 78 0 85 2 85 20 0 17 7
    20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33
    0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20
    20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17
    0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 18 0 20 7 20
    80 0 73 2 80 20 80 20 0 20 7 20 3710 0 3703 0 3710 -20 3710 -18 0 -20 7 -20
    80 0 73 -2 80 -20 80 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20
    40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20
    20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20
    20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20
    20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40
    20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40
    20 0 18 -7 20 -85 20 -78 0 -85 2 -85 20 0 20 -7 20 -480 20 -473 0 -480 0
    -480 -20z m960 -640 c0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20
    -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -15 7 -20 25
    -20 l25 0 0 -3710 0 -3710 -25 0 c-18 0 -25 -5 -25 -20 0 -13 -7 -20 -20 -20
    -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20
    -20 -20 -13 0 -20 -7 -20 -20 0 -20 -7 -20 -480 -20 -473 0 -480 0 -480 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -20 0 -20 7 -20 3710 0
    3703 0 3710 20 3710 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 20 7 20 480 20
    473 0 480 0 480 -20z" />
                    <path d="M7900 8040 c0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7 -20
    -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0
    -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40
    0 -33 -3 -40 -20 -40 -18 0 -20 -7 -20 -80 0 -73 -2 -80 -20 -80 -20 0 -20 -7
    -20 -2905 0 -2898 0 -2905 20 -2905 18 0 20 -7 20 -80 0 -73 2 -80 20 -80 17
    0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0
    20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0
    40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 80 -20 79 0
    80 0 80 -25 l0 -25 485 0 485 0 0 25 c0 25 1 25 80 25 73 0 80 2 80 20 0 17 7
    20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33
    0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20
    20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17
    0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 18 0 20 7 20
    80 0 79 0 80 25 80 l25 0 0 2905 0 2905 -25 0 c-25 0 -25 1 -25 80 0 73 -2 80
    -20 80 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40
    -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20
    -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20
    -80 20 -73 0 -80 2 -80 20 0 20 -7 20 -485 20 -478 0 -485 0 -485 -20z m970
    -640 c0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20
    -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 20 0 20 -7 20
    -2905 0 -2898 0 -2905 -20 -2905 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20
    -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20
    -20 -20 -13 0 -20 -7 -20 -20 0 -20 -7 -20 -485 -20 -478 0 -485 0 -485 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -20 0 -20 7 -20 2905 0
    2898 0 2905 20 2905 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 20 7 20 485 20
    478 0 485 0 485 -20z" />
                    <path d="M1450 6430 c0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -17 -7 -20
    -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0
    -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -45
    0 -38 -3 -45 -20 -45 -18 0 -20 -7 -20 -80 0 -73 -2 -80 -20 -80 -20 0 -20 -7
    -20 -2095 0 -2088 0 -2095 20 -2095 18 0 20 -7 20 -80 0 -73 2 -80 20 -80 17
    0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 17 0
    20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0
    20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0
    40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 80 -20 79 0
    80 0 80 -25 l0 -25 485 0 485 0 0 25 c0 25 1 25 80 25 73 0 80 2 80 20 0 17 7
    20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33
    0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20
    20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0 33 3 40 20 40 17
    0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 18 0 20 7 20
    80 0 79 0 80 25 80 l25 0 0 2095 0 2095 -25 0 c-25 0 -25 1 -25 80 0 73 -2 80
    -20 80 -17 0 -20 7 -20 45 0 38 -3 45 -20 45 -17 0 -20 7 -20 40 0 33 -3 40
    -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20
    -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20
    -80 20 -73 0 -80 2 -80 20 0 20 -7 20 -485 20 -478 0 -485 0 -485 -20z m970
    -650 c0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20
    -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 20 0 20 -7 20
    -2095 0 -2088 0 -2095 -20 -2095 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20
    -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20
    -20 -20 -13 0 -20 -7 -20 -20 0 -20 -7 -20 -485 -20 -478 0 -485 0 -485 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -20 0 -20 7 -20 2095 0
    2088 0 2095 20 2095 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7
    20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 20 7 20 485 20
    478 0 485 0 485 -20z" />
   
</g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'BarChartIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}

.icon-wrapper svg {
  padding: 0;
  margin: 0;
  display: block;
}
.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  