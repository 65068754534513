import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
  namespaced: true,
  state: {
    user: {},
    selectedCurrency: {
      initialValue: true
    },
    tourStep: -1,
    userGeneral: {}
  },
  getters: {
    getUser : state => state.user,
    userDisplayName: _ => firebase.auth().currentUser?.displayName,
    isAuthenticated: _ => firebase.auth().currentUser?.uid != null,
    currentCurrencySymbol: state => state.selectedCurrency?.Symbol ?? '€'
  },
  mutations: {
    setUser (state, user) {
      state.user = user;
    },
    setUserGeneral (state, userGeneral) {
      state.userGeneral = userGeneral;
    },
    setCurrency(state, currency){
      state.selectedCurrency = currency;
    },
    setTourStep(state, tourStep){
      state.tourStep = tourStep;
    }
  },
  actions: {
    SetUser(context, user) {
      context.commit('setUser', user)
    },
    SetCurrency(context, currency) {
      firebase.firestore().collection("currency").doc(firebase.auth().currentUser.uid).set(currency)
      context.commit('setCurrency', currency)
    },
    ValidateShopifyAuthRequest({dispatch}, data){
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {path: '/verifyHMAC', body: data 
        }, {root: true}).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetTourStep({commit}) {
      console.log("fetching tourstep from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).get().then(x => {
          let tourstep = x.data()?.tourStep ?? -1;
          commit("setTourStep", tourstep)
          resolve(tourstep)
        }).catch(e => {
          console.log(e); 
          reject(e)
        })
      });
    },
    GetUserDataGeneral({commit}) {
      console.log("fetching User Data from firebase...")
      return new Promise((resolve, reject) => {
        firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).get().then(x => {
          const dataX = x.data();
          let userGeneralEmail = dataX?.email ?? "";
          let profitEmail = dataX?.profitEmail ?? false;
          let incomeTax = dataX?.incomeTax ?? "";
          let incomeTaxNumber = dataX?.incomeTaxNumber ?? "";
          let pinterest = dataX?.pinterest ?? false;
          let facebook = dataX?.facebook ?? false;
          let snapchat = dataX?.snapchat ?? false;
          let google = dataX?.google ?? false;
          let tiktok = dataX?.tiktok ?? false;
          let crossVariant = dataX.crossVariant ?? false;
          let importTotal = dataX.importTotal ?? false;
          let pinterestTwo = dataX.pinterestTwo ?? false;

          let userGeneral = {email: userGeneralEmail, profitEmail: profitEmail, incomeTax: incomeTax, incomeTaxNumber: incomeTaxNumber, pinterest: pinterest, snapchat: snapchat, facebook: facebook, google: google, tiktok: tiktok, crossVariant: crossVariant, importTotal: importTotal, pinterestTwo: pinterestTwo}
          commit("setUserGeneral", userGeneral)
          resolve(userGeneral)
        }).catch(e => {
          console.log(e); 
          reject(e)
        })
      });
    },
    SetTourStep(context, tourStep) {
      firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).set({tourStep: tourStep}, {merge: true})
      context.commit('setTourStep', tourStep)
    },
    SetUserDataGeneral(context, userDataG) {
      firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).set({email: userDataG.email, incomeTax: userDataG.incomeTax, incomeTaxNumber: userDataG.incomeTaxNumber}, {merge: true})
      context.commit('setUserGeneral', userDataG)
    },
    async SetUserDefaultMargin(context, margin) {
      await firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).set({defaultMargin: margin}, {merge: true})
      return true
    },
    async SetUserStoreName(context, name) {
      await firebase.firestore().collection("userdata").doc(firebase.auth().currentUser.uid).set({storeName: name}, {merge: true})
      return true
    },
    
  },
}