<template>
    <div class="icon-wrapper">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="17pt" height="17pt" viewBox="0 0 256 256" xml:space="preserve" >
   <path fill="#1D2752" opacity="1.000000" stroke="none" 
       d="
   M136.042938,237.252167 
       C124.627571,231.942642 124.063766,213.260101 133.260376,206.302231 
       C134.363892,205.467331 135.244568,203.273682 135.015778,201.913818 
       C133.474335,192.750885 131.773224,183.604095 129.671661,174.557114 
       C129.335770,173.111160 126.789040,171.282654 125.164932,171.178268 
       C115.033020,170.527023 106.574631,166.262619 99.262634,159.626572 
       C96.685478,157.287643 94.831085,157.026062 91.921684,158.905533 
       C86.063019,162.690216 79.968758,166.111694 73.951691,169.648987 
       C71.468941,171.108536 70.496086,172.756699 70.793243,176.043488 
       C72.145325,190.998703 67.093262,200.548111 55.251377,205.221146 
       C41.778240,210.537872 24.827826,205.451813 20.116970,189.461411 
       C15.731483,174.575439 22.112799,159.624680 38.367134,155.538620 
       C45.765564,153.678787 53.348843,154.601685 59.948708,159.056107 
       C62.248970,160.608612 63.959583,160.415482 66.202545,159.004318 
       C72.811584,154.846161 79.664162,151.067032 86.170219,146.760101 
       C87.464172,145.903519 88.519531,143.181961 88.199142,141.626419 
       C85.317429,127.635063 86.550117,114.484421 95.610313,102.326317 
       C88.061646,93.985703 80.685410,85.681946 73.049133,77.624634 
       C72.243416,76.774490 69.750755,76.897041 68.306862,77.374001 
       C52.701458,82.528893 35.471886,76.914505 29.113451,61.163830 
       C21.210152,41.586311 33.565365,19.062716 55.961418,18.913834 
       C69.630119,18.822968 79.412598,24.424454 84.832825,36.880234 
       C88.352013,44.967388 88.508705,53.522877 84.575348,61.508038 
       C82.493439,65.734550 82.539818,68.343246 86.059517,71.798164 
       C92.090683,77.718315 97.324783,84.443695 103.192749,90.543137 
       C104.251434,91.643570 107.165810,92.232521 108.514183,91.583672 
       C122.194893,85.000519 135.768829,84.905098 149.300262,91.723114 
       C151.931839,93.049057 153.419983,92.402084 155.207672,90.367683 
       C163.640839,80.770645 172.313583,71.380852 180.580109,61.644791 
       C181.624847,60.414345 181.420547,57.322926 180.706604,55.526150 
       C174.915680,40.952366 181.653931,24.793837 196.323730,20.182007 
       C210.337860,15.776301 225.848633,21.525629 230.062424,37.197990 
       C234.038361,51.985683 227.605347,66.392715 211.920837,70.270103 
       C207.288422,71.415283 201.991470,70.806549 197.178787,69.968941 
       C193.812256,69.383041 191.495468,69.577965 189.280685,72.180588 
       C184.976028,77.239044 180.568512,82.211189 176.146942,87.168549 
       C171.728500,92.122383 167.240753,97.014427 162.626328,102.106903 
       C163.903717,104.386086 165.092499,106.527344 166.299927,108.658043 
       C171.765091,118.302315 172.239716,128.676712 169.988953,139.135254 
       C169.056290,143.469055 169.913040,145.851913 173.582489,148.165192 
       C181.875717,153.393326 189.973557,158.937286 198.055298,164.491821 
       C200.899124,166.446381 203.306931,166.940002 206.686356,165.262360 
       C218.837006,159.230469 232.320526,163.856705 237.511169,176.280212 
       C243.997879,191.805756 232.315262,207.181885 218.225204,207.049332 
       C203.555832,206.911346 194.972214,198.739578 195.100372,183.944260 
       C195.136719,179.748322 193.715790,177.224915 190.258820,175.045486 
       C181.965027,169.816696 173.834747,164.324539 165.718796,158.820221 
       C163.522675,157.330795 161.944794,157.006927 159.559143,158.829895 
       C155.098434,162.238480 150.346802,165.318222 145.474228,168.112869 
       C143.216949,169.407547 142.443222,170.647858 142.954773,173.135712 
       C144.759430,181.912308 146.202148,190.772873 148.344162,199.463074 
       C148.819366,201.391052 151.357712,203.163040 153.349869,204.298416 
       C159.322006,207.702118 162.692947,212.426727 163.045258,219.460754 
       C163.428329,227.109161 161.209473,233.378860 154.232117,237.055588 
       C148.480255,240.086578 142.381546,239.753906 136.042938,237.252167 
   M105.143524,146.261032 
       C106.251587,147.495010 107.302345,148.786621 108.476715,149.953888 
       C118.519432,159.935776 135.487335,161.040359 146.839249,152.477066 
       C158.164856,143.933624 161.691360,126.540039 154.575562,114.319908 
       C146.459351,100.381767 127.168968,95.417099 113.755196,103.814171 
       C99.414268,112.791634 95.386566,131.619141 105.143524,146.261032 
   M60.042152,32.050751 
       C58.876335,32.059299 57.710518,32.074760 56.544697,32.075275 
       C49.167892,32.078541 43.460754,35.533409 41.006432,42.301437 
       C38.801224,48.382511 39.347130,56.611996 45.028915,61.611332 
       C49.998119,65.983673 58.078945,67.633301 64.093620,64.919540 
       C71.448952,61.600887 74.658043,55.469002 73.962631,46.061985 
       C73.496307,39.753979 68.441025,34.349968 60.042152,32.050751 
   M193.446228,38.307953 
       C192.972412,41.032589 192.144852,43.751923 192.103912,46.483051 
       C192.034607,51.108913 196.723129,56.698326 201.077606,57.770985 
       C207.198303,59.278717 213.266556,56.865273 216.604294,51.595737 
       C218.988693,47.831310 218.235641,40.663960 215.588440,37.060349 
       C210.812668,30.559191 199.444016,29.418484 193.446228,38.307953 
   M45.413647,168.040924 
       C43.459637,168.305725 41.335457,168.167862 39.582584,168.908630 
       C33.626736,171.425552 30.467655,178.804810 32.306458,185.227554 
       C33.969490,191.036331 40.885391,194.884613 47.910187,193.910095 
       C53.764809,193.097900 58.220760,186.915878 57.989422,179.926559 
       C57.777031,173.509888 53.121716,168.790497 45.413647,168.040924 
   M214.962662,193.900253 
       C215.794708,193.921967 216.629456,193.997818 217.458389,193.956680 
       C223.285889,193.667465 226.119949,190.552460 225.982712,184.620865 
       C225.854797,179.092560 222.800598,176.185226 217.198654,176.036896 
       C212.626968,175.915863 209.387024,177.689423 208.227310,182.244385 
       C206.840347,187.692001 208.767105,191.252701 214.962662,193.900253 
   M140.300766,219.028793 
       C139.810669,221.991898 139.863266,225.100342 143.462204,225.600662 
       C145.325882,225.859772 148.382324,224.833725 149.184174,223.417114 
       C150.012360,221.953979 149.497620,218.554718 148.259338,217.350189 
       C145.903595,215.058640 142.862274,215.460297 140.300766,219.028793 
   z"/>
   
   </svg>
    </div>
</template>
  
<script>
export default {
    name: 'BarChartIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
}

.icon-wrapper svg {
  padding: 0;
  margin: 0;
  display: block;
}
.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  