<template>
  <div id="app">
    <component :is="layout">
      <router-view v-slot="{ Component, route }">
        <transition name="fade">
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    </component>
  </div>
</template>

<script>
  import baseLayout from './layouts/baseLayout.vue'
  import authenticatedLayout from './layouts/authenticatedLayout.vue'
  import { mapActions } from 'vuex'
  
  const default_layout = "base";

  export default {
    components: {baseLayout, authenticatedLayout},
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + 'Layout';
      }
    },
    methods:{
    },
    mounted(){
    
    }
  }
</script>

<style lang="scss">

// Variables
@import '@/assets/scss/main.scss';
@import '@/assets/scss/xprofit-layout.scss';
@import url('https://fonts.cdnfonts.com/css/gibson');

// Global css

html{
  min-height: 100%;
  min-width: 100%;
  position: relative;
  //font-family: 'Gibson', sans-serif;
}

body{
  min-height: 100%;
  min-width: 100%;
  margin:0;
  background-color: $background-grey;
  font-family: 'Gibson', sans-serif !important;
  color: $text-color !important;
  letter-spacing: 0.3px;
  position: relative;
}

#app{
  margin:0;
  top:0;
  height: 100%;
  width:100%;
  background-color: $background-grey;
  position: relative;
}

h1.pageTitle{
  font-size: 20px;
  font-weight: 600;
  color: $text-color;
}


.pageBlock{
  background-color:white;
  width: 100%;
  padding: 8px 12px;
  border-radius: 15px;
  margin-bottom: calc($layoutDefaultMargin / 2);
  >.blockTitle{
    font-size: 20px;
  }
}

.primary-button{
  position: relative;
  border-radius: 10px;
  border:none;
  height: 35px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: 15px;
  cursor: pointer;
  background-color: #454f63;
  &:hover{
    background-color: #555b69;
    >svg{
      //color: $xprofit;
    }
  }
  >svg{
    position: absolute;
    left: 10px;
    top: 4px;
    font-size: 25px;
  }
  padding: 0 40px;
}

button.close{
  border:none;
  background-color: transparent;
}


h1,h2,h3,h4{
  margin-top: 0;
  font-weight: normal;
}

.mt10{
  margin-top: 10px !important;
}
.mt20{
  margin-top: 20px !important;
}
.mt30{
  margin-top: 20px !important;
}
.mt40{
  margin-top: 20px !important;
}
.mt50{
  margin-top: 30px !important;
}
</style>