<template lang="pug">
.baseLayout
  pageError
  slot
    
</template>

<script>
import pageError from "../components/pageError.vue"

export default {
  components:{
    pageError
  }
}
</script>

<style scoped lang="scss">

.baseLayout{
  width: 100%;
  min-height: 100vh;
  background: $background-grey;
}

</style>

