<template lang="pug">
b-modal#ReconnectAdAccountOnboarding(centered hide-footer hide-header button-size="sm" no-close-on-backdrop)
    .modalContent
    .close(@click="hideModal")
        span close
    .title
        span.black Your account is already connected
    .textual If you wish to reconnect, please select the 'reconnect' button. Please note that recalculations may take up to 4 minutes
    LocalLoader(v-if="loading")
    button.saveCj(v-else @click="onReconnect") Reconnect {{ platform }}
          
            
</template>
    <script>
        
        import { mapActions } from 'vuex'
        export default {
          components: {
        
          },
          data() {
            return {
              selectedAccount: "",
              platform: "",
              loading: false,
            }
          },
          computed: {
            computedDisabledDates() {
        
        
            }
          },
          methods: {
            ...mapActions(["AddPageError"]),
            launchModal(platform) {
    
              this.platform = platform
              this.loading = false;
        
              this.$bvModal.show('ReconnectAdAccountOnboarding');
            },
        
        
            hideModal() {
        
              this.loading = false;
              this.$bvModal.hide('ReconnectAdAccountOnboarding');
        
            },
            async onReconnect() {
        
                this.loading = true;
                 this.$emit('reconnect', true);
                 
                    this.hideModal()
                    
    
                // if (this.platform == "pinterest") {
                //     this.$emit('reconnect', true);
                //     hideModal()
                
                // } else if (this.platform == "facebook") {
                //     this.$emit('reconnect', true);
                //     hideModal()
              
        
                // } else if (this.platform == "snapchat") {
        
                //     this.$emit('reconnect', true);
                //     hideModal()
        
                // } else if (this.platform == "google") {
                //     this.$emit('reconnect', true);
                //     hideModal()
                // } else if (this.platform == "tiktok") {
                //     this.$emit('reconnect', true);
                //     hideModal()
        
                // }
              
        
            },
        
        
          }
        
        }
        </script>
        <style lang="scss" scoped>
        #ReconnectAdAccountOnboarding {
        
          .modalContent {
            min-height: 200px;
            display: flex;
            flex-direction: column;
            padding: 10px;
          }
        
          .title {
            .black {
              color: $text-color;
            }
          }
        
          .textual {
            font-size: 14px;
            padding-right: 50px;
            padding-top: 30px;
          }
    
        
          .close {
            position: absolute;
            right: 20px;
            color: lightgrey;
            cursor: pointer;
            font-weight: 100;
            top: 20px;
        
            &:hover {
              color: black
            }
          }
    
          .saveCj {
            background-color: $background-grey;
            border-radius: 6px;
            margin-right: 10px;
            color: $text-color;
            border: none;
            font-size: $small-Font-Size;
            padding: 5px 10px;
            margin-left: 10px;
            margin-top: 50px;
        }
        
        
        }
        </style>