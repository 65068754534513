import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
  namespaced: true,
  state: {
    products: [],
    orders: [],
    loadingProductInfo: false,
    redoCalculations: false,
    loadedAllProductCost: false
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setRedoCalculations(state, value) {
      state.redoCalculations = value;
    },
    setloadingProductInfo(state, value) {
      state.loadingProductInfo = value;
    },
    setloadedAllProductCost(state, value) {
      state.loadedAllProductCost = value;
    }
  },
  actions: {
    GetToken({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/getToken', body: data
        }, { root: true }).then(x => {
          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    GetCustomToken({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/getCustomToken', body: data
        }, { root: true }).then(x => {
          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    FetchProductsFromDatabase({ commit, dispatch }) {
      console.log("fetching targeted products from firebase...")
      return new Promise((resolve, reject) => {
        dispatch('api/apiGetWithToken', { path: '/shopify-FetchProducts' },
          { root: true }).then(x => {
            var sortedata = x.data?.sort(function (a, b) {
              return new Date(b.created_at) - new Date(a.created_at);
            }) ?? [];
            commit("setProducts", sortedata)
            resolve()
          }).catch(error => {
            reject(error);
          });
      })

    },

    FetchTargetedProductsFromDatabase({ commit, dispatch, state }, IDs) {
      console.log("fetching targeted products from firebase...")
      return new Promise((resolve, reject) => {
        commit("setloadingProductInfo", true)
        var newIDS = [];
        var existing = state.products

        const objectWithIdAsKey = {};

        existing.forEach(obj => {
          objectWithIdAsKey[obj.id] = obj;
        });

        for (const id of IDs) {
          if (objectWithIdAsKey[id] == null) {
            newIDS.push(id)
          }
        }

        console.log("new d", newIDS.length)

        dispatch('api/apiPostWithToken', {
          path: '/shopify-FetchProductsIDs',
          body: {
            data: newIDS,
          }
        }, { root: true }).then(x => {

          var result = x.data

          const mergedArray = [...result, ...existing];

          var sortedata = mergedArray.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          }) ?? [];
          commit("setProducts", sortedata)
          commit("setloadingProductInfo", false)
          resolve()
        }).catch(error => {
          reject(error);
        });
      })

    },

    FetchAllProductsFromDatabase({ commit, dispatch }) {
      console.log("fetching targeted products from firebase...")
  
      return new Promise((resolve, reject) => {
        commit("setloadedAllProductCost", true)
        dispatch('api/apiGetWithToken', { path: '/shopify-FetchAllProducts' },
          { root: true }).then(x => {
            var sortedata = x.data?.sort(function (a, b) {
              return new Date(b.created_at) - new Date(a.created_at);
            }) ?? [];
            commit("setProducts", sortedata)
            
            resolve()
          }).catch(error => {
            reject(error);
          });
      })

    },
    CaculationRequired({ commit }) {
      commit("setRedoCalculations", true)
    },
    FetchNewOrdersInitial({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/orderFetching-FetchNewOrdersInitialNew', body: data
        }, { root: true }).then(x => {

          resolve(x)
        }).catch(error => {
          reject(error);
        });
      })
    },
    async FetchFirstInfo({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/orderFetching-FirstSignUp'
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    },
    FetchFirstMarketing({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('api/apiPostWithToken', {
          path: '/orderFetching-FetchMarketingInitial'
        }, { root: true }).then(x => {

          resolve(x.data)
        }).catch(error => {
          reject(error);
        });
      })
    }

  },
}

function AddFilters(filters) {
  if (filters == null || Object.keys(filters).length == 0)
    return ""

  var arrayWithParams = Object.keys(filters).map(x => {
    return `${x}=${filters[x]}`
  })
  return "?" + arrayWithParams.join("&");
}