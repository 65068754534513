<template lang="pug">
.logo-container
  .logo(:style="{fontSize: size+'px'}") XProfit
</template>
    
  <script>
  export default {
    props:{
      size: Number
    }
  }
  
  </script>
  
  <style scoped lang="scss">
  .logo-container{
    .logo{
      &::first-letter{
        color: $xprofit;
      }
    }
  }
  
  </style>