<template lang="pug">
.wrapper
    .information-marker(:id="'infoMarker_' + uniqueId")
      b-icon(icon="info-circle-fill")
    b-popover(:target="'infoMarker_' + uniqueId" title="" :triggers="trigger ?? 'click'" :placement="placement ?? 'top'")
      slot
</template>
  
  <script>
  export default {
    props: {
      trigger: String,
      placement: String,
      uniqueId: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
  }
  
  .information-marker {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  </style>
  