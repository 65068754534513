<template>
    <div class="icon-wrapper">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14pt" height="14pt" viewBox="20 40 992 992"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1032.000000) scale(0.100000,-0.100000)" fill="#1D2752" stroke="none">
                <path d="M4070 9860 c0 -19 -7 -20 -200 -20 -193 0 -200 -1 -200 -20 0 -19 -7
    -20 -160 -20 -153 0 -160 -1 -160 -20 0 -19 -7 -20 -105 -20 -98 0 -105 -1
    -105 -20 0 -19 -7 -20 -100 -20 l-100 0 0 -25 0 -25 -100 0 c-93 0 -100 -1
    -100 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -18 -7 -20 -60 -20 -53
    0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60
    -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
    -20 -60 -20 -53 0 -60 -2 -60 -20 0 -17 -7 -20 -45 -20 -38 0 -45 -3 -45 -20
    0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3
    -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33
    0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40
    -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20
    0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20
    0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17
    0 -20 -7 -20 -45 0 -41 -2 -45 -25 -45 -22 0 -25 -4 -25 -40 0 -33 -3 -40 -20
    -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -18 0 -20 -7 -20 -80 0 -73 -2 -80 -20 -80 -20 0 -20 -7 -20
    -1065 0 -1058 0 -1065 20 -1065 18 0 20 -7 20 -85 0 -78 2 -85 20 -85 18 0 20
    -7 20 -60 0 -53 2 -60 20 -60 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 18 0 20
    -7 20 -60 0 -57 1 -60 25 -60 18 0 25 -5 25 -20 0 -13 7 -20 20 -20 17 0 20
    -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20
    -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20
    -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20
    -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20
    -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40
    -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40
    -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40
    -3 40 -20 0 -13 -7 -20 -20 -20 -19 0 -20 -7 -20 -105 0 -98 -1 -105 -20 -105
    -20 0 -20 -7 -20 -1005 0 -998 0 -1005 20 -1005 19 0 20 -7 20 -100 0 -93 1
    -100 20 -100 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 18 0 20 -7 20 -60 0 -53 2
    -60 20 -60 17 0 20 -7 20 -45 0 -38 3 -45 20 -45 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -15 7 -20 25 -20 18 0 25 -5 25 -20 0 -13 7
    -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7
    -20 60 -20 53 0 60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7
    -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
    -20 85 -20 78 0 85 -2 85 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
    -20 80 -20 79 0 80 0 80 -25 0 -25 1 -25 80 -25 73 0 80 -2 80 -20 0 -19 7
    -20 100 -20 93 0 100 -1 100 -20 0 -19 7 -20 120 -20 113 0 120 -1 120 -20 0
    -19 7 -20 145 -20 138 0 145 -1 145 -20 0 -19 7 -20 220 -20 213 0 220 -1 220
    -20 0 -20 7 -20 645 -20 638 0 645 0 645 20 0 19 7 20 220 20 213 0 220 1 220
    20 0 19 7 20 140 20 133 0 140 1 140 20 0 19 7 20 105 20 98 0 105 1 105 20 0
    19 7 20 100 20 93 0 100 1 100 20 0 19 7 20 100 20 l100 0 0 25 c0 25 1 25 80
    25 73 0 80 2 80 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60
    2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0
    18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 45 20 38 0 45 3 45 20 0 17 7 20 40
    20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 18 7 20 60 20 53 0 60
    2 60 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20 33 0 40 3 40 20 0
    17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 40
    20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20
    7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0
    17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20
    20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20
    7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 17 0 20 7 20 40 0
    33 3 40 20 40 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 15 7 20 25
    20 23 0 25 4 25 40 0 33 3 40 20 40 17 0 20 7 20 45 0 38 3 45 20 45 17 0 20
    7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40 20 40 18 0 20 7 20 80 0
    73 2 80 20 80 20 0 20 7 20 1065 0 1058 0 1065 -20 1065 -18 0 -20 7 -20 85 0
    78 -2 85 -20 85 -18 0 -20 7 -20 60 0 53 -2 60 -20 60 -17 0 -20 7 -20 40 0
    33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 0
    36 -2 40 -25 40 -22 0 -25 4 -25 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 7 20 20 20 19 0 20 7 20 105 0 98 1
    105 20 105 20 0 20 7 20 1005 0 998 0 1005 -20 1005 -19 0 -20 7 -20 100 0 93
    -1 100 -20 100 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -18 0 -20 7 -20 60 0 53
    -2 60 -20 60 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -15 0 -20 7 -20 25 0 18
    -5 25 -20 25 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13
    -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13
    -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13
    -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17
    -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 15
    -7 20 -25 20 -18 0 -25 5 -25 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17
    -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17
    -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17
    -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17
    -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17
    -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18
    -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18
    -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18
    -7 20 -65 20 -58 0 -65 2 -65 20 0 18 -7 20 -80 20 -73 0 -80 2 -80 20 0 18
    -7 20 -80 20 -73 0 -80 2 -80 20 0 18 -7 20 -80 20 -79 0 -80 0 -80 25 l0 25
    -100 0 c-93 0 -100 1 -100 20 0 19 -7 20 -100 20 -93 0 -100 1 -100 20 0 19
    -7 20 -160 20 -153 0 -160 1 -160 20 0 19 -7 20 -225 20 -218 0 -225 1 -225
    20 0 20 -7 20 -645 20 -638 0 -645 0 -645 -20z m1330 -850 c0 -19 7 -20 205
    -20 198 0 205 -1 205 -20 0 -19 7 -20 120 -20 113 0 120 -1 120 -20 0 -19 7
    -20 100 -20 93 0 100 -1 100 -20 0 -19 7 -20 100 -20 93 0 100 -1 100 -20 0
    -18 7 -20 80 -20 73 0 80 -2 80 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
    -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
    -18 7 -20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0
    -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0
    -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0
    -17 7 -20 40 -20 36 0 40 -2 40 -25 0 -18 5 -25 20 -25 13 0 20 -7 20 -20 0
    -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0
    -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0
    -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0
    -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -40 0
    -33 3 -40 20 -40 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 18 0 20 -7 20 -60 0
    -53 2 -60 20 -60 18 0 20 -7 20 -80 0 -73 -2 -80 -20 -80 -18 0 -20 -7 -20
    -60 0 -53 -2 -60 -20 -60 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0
    -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20
    0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -17 -7 -20 -40 -20 -36 0 -40 -2 -40 -25 0 -18 -5 -25 -20 -25 -13
    0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40
    -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7
    -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20
    0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -65 -20 -58 0 -65 -2
    -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53
    0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -80
    -20 -73 0 -80 -2 -80 -20 0 -19 -7 -20 -100 -20 -93 0 -100 -1 -100 -20 0 -19
    -7 -20 -100 -20 -93 0 -100 -1 -100 -20 0 -19 -7 -20 -145 -20 -138 0 -145 -1
    -145 -20 0 -19 -7 -20 -180 -20 -173 0 -180 -1 -180 -20 0 -20 -7 -20 -665
    -20 -658 0 -665 0 -665 20 0 19 -7 20 -200 20 -193 0 -200 1 -200 20 0 19 -7
    20 -120 20 -113 0 -120 1 -120 20 0 19 -7 20 -125 20 -118 0 -125 1 -125 20 0
    18 -7 20 -80 20 -73 0 -80 2 -80 20 0 18 -7 20 -80 20 -73 0 -80 2 -80 20 0
    18 -7 20 -80 20 -73 0 -80 2 -80 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0
    18 -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0
    18 -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0
    17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0
    17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -45 20 -38 0 -45 3 -45 20 0
    17 -7 20 -40 20 -36 0 -40 3 -40 25 0 18 -5 25 -20 25 -13 0 -20 7 -20 20 0
    17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0
    13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0
    33 -3 40 -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -19 0 -20 7 -20 120 0
    113 1 120 20 120 17 0 20 7 20 40 0 33 3 40 20 40 17 0 20 7 20 40 0 33 3 40
    20 40 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0
    20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20
    0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20
    40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20 36 0
    40 3 40 25 0 23 4 25 45 25 38 0 45 3 45 20 0 17 7 20 40 20 33 0 40 3 40 20
    0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20
    40 20 33 0 40 3 40 20 0 18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20 33 0
    40 3 40 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20
    0 18 7 20 80 20 73 0 80 2 80 20 0 18 7 20 80 20 73 0 80 2 80 20 0 18 7 20
    80 20 73 0 80 2 80 20 0 19 7 20 125 20 118 0 125 1 125 20 0 19 7 20 120 20
    113 0 120 1 120 20 0 19 7 20 180 20 173 0 180 1 180 20 0 20 7 20 685 20 678
    0 685 0 685 -20z m-4030 -2620 c0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 45 -20 38 0 45 -3 45 -20 0 -18 7
    -20 60 -20 53 0 60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7
    -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
    -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
    -20 60 -20 57 0 60 -1 60 -25 0 -25 1 -25 80 -25 73 0 80 -2 80 -20 0 -19 7
    -20 100 -20 93 0 100 -1 100 -20 0 -18 7 -20 85 -20 78 0 85 -2 85 -20 0 -19
    7 -20 120 -20 113 0 120 -1 120 -20 0 -19 7 -20 160 -20 153 0 160 -1 160 -20
    0 -20 7 -20 885 -20 878 0 885 0 885 20 0 19 7 20 165 20 158 0 165 1 165 20
    0 19 7 20 120 20 113 0 120 1 120 20 0 19 7 20 100 20 93 0 100 1 100 20 0 19
    7 20 100 20 93 0 100 1 100 20 0 18 7 20 60 20 57 0 60 1 60 25 0 25 1 25 80
    25 73 0 80 2 80 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 65 20 58 0 65
    2 65 20 0 18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0
    18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0 18 7 20 60
    20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40
    3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0
    17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40
    20 l40 0 0 -240 c0 -233 -1 -240 -20 -240 -17 0 -20 -7 -20 -40 0 -33 -3 -40
    -20 -40 -17 0 -20 -7 -20 -45 0 -38 -3 -45 -20 -45 -13 0 -20 -7 -20 -20 0
    -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7
    -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33
    0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7
    -20 -20 -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20
    0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3
    -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33
    0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -18 -7 -20 -65
    -20 -58 0 -65 -2 -65 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7
    -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20
    0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2
    -80 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -19 -7 -20 -120 -20
    -113 0 -120 -1 -120 -20 0 -19 -7 -20 -145 -20 -138 0 -145 -1 -145 -20 0 -19
    -7 -20 -240 -20 -233 0 -240 -1 -240 -20 0 -20 -7 -20 -485 -20 -478 0 -485 0
    -485 20 0 19 -7 20 -220 20 -213 0 -220 1 -220 20 0 19 -7 20 -140 20 -133 0
    -140 1 -140 20 0 19 -7 20 -120 20 -113 0 -120 1 -120 20 0 19 -7 20 -105 20
    -98 0 -105 1 -105 20 0 18 -7 20 -80 20 -73 0 -80 2 -80 20 0 18 -7 20 -60 20
    -53 0 -60 2 -60 20 0 18 -7 20 -80 20 -73 0 -80 2 -80 20 0 18 -7 20 -60 20
    -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20 -60 20
    -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 18 -7 20 -60 20
    -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20
    -33 0 -40 3 -40 20 0 15 -7 20 -25 20 -18 0 -25 5 -25 20 0 17 -7 20 -40 20
    -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20
    -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -17 0 -20 7 -20 45 0 38 -3 45 -20 45 -19 0 -20 7 -20 260 l0 260 40 0 c33 0
    40 -3 40 -20z m7140 -1450 c0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -13 7 -20
    20 -20 13 0 20 -7 20 -20 0 -13 7 -20 20 -20 17 0 20 -7 20 -45 0 -38 3 -45
    20 -45 17 0 20 -7 20 -40 0 -33 3 -40 20 -40 19 0 20 -7 20 -140 0 -133 -1
    -140 -20 -140 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20
    -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0
    -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -17 0 -20 -7 -20 -40 l0 -40 -40 0 c-33
    0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -17 -7
    -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20
    0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -45 -20 -38 0 -45 -3
    -45 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33
    0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -18 -7 -20 -60
    -20 -53 0 -60 -2 -60 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -18 -7
    -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20
    0 -18 -7 -20 -80 -20 -79 0 -80 0 -80 -25 0 -25 -1 -25 -80 -25 -73 0 -80 -2
    -80 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -19 -7 -20 -105 -20 -98
    0 -105 -1 -105 -20 0 -19 -7 -20 -120 -20 -113 0 -120 -1 -120 -20 0 -19 -7
    -20 -160 -20 -153 0 -160 -1 -160 -20 0 -20 -7 -20 -385 -20 -378 0 -385 0
    -385 -20 0 -18 -7 -20 -60 -20 -53 0 -60 2 -60 20 0 20 -7 20 -380 20 -373 0
    -380 0 -380 20 0 19 -7 20 -165 20 -158 0 -165 1 -165 20 0 19 -7 20 -120 20
    -113 0 -120 1 -120 20 0 19 -7 20 -100 20 -93 0 -100 1 -100 20 0 18 -7 20
    -80 20 -73 0 -80 2 -80 20 0 18 -7 20 -80 20 -79 0 -80 0 -80 25 0 25 -1 25
    -80 25 -73 0 -80 2 -80 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20
    -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -45 20 -38 0 -45 3 -45 20 0 18 -7 20
    -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20
    -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20
    -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20
    -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20
    -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20
    -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -17 0 -20 7 -20 40 0 33 -3 40
    -20 40 -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -17 0 -20 7 -20 40 l0 40 60 0
    c53 0 60 -2 60 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 80 -20
    73 0 80 -2 80 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7 -20 80 -20
    73 0 80 -2 80 -20 0 -19 7 -20 100 -20 93 0 100 -1 100 -20 0 -19 7 -20 105
    -20 98 0 105 -1 105 -20 0 -19 7 -20 100 -20 93 0 100 -1 100 -20 0 -19 7 -20
    140 -20 133 0 140 -1 140 -20 0 -19 7 -20 260 -20 253 0 260 -1 260 -20 0 -20
    7 -20 465 -20 458 0 465 0 465 20 0 19 7 20 260 20 253 0 260 1 260 20 0 19 7
    20 165 20 158 0 165 1 165 20 0 19 7 20 120 20 113 0 120 1 120 20 0 19 7 20
    100 20 93 0 100 1 100 20 0 19 7 20 100 20 93 0 100 1 100 20 0 18 7 20 80 20
    73 0 80 2 80 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 85 20 78 0 85 2
    85 20 0 18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0 18
    7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 17 7 20 40 20
    33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3
    40 20 0 17 7 20 40 20 36 0 40 3 40 25 0 23 4 25 40 25 33 0 40 3 40 20 0 17
    7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 40 20
    33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20 20 13 0 20 7
    20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 17 7 20 45 20 38 0 45 3 45 20 0 13
    7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20 13 0 20 7 20 20 0 13 7 20 20 20
    13 0 20 -7 20 -20z m-6290 -1980 c0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -13
    7 -20 20 -20 13 0 20 -7 20 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -18 7
    -20 60 -20 53 0 60 -2 60 -20 0 -17 7 -20 40 -20 33 0 40 -3 40 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -17 7
    -20 40 -20 33 0 40 -3 40 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
    -20 65 -20 58 0 65 -2 65 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
    -20 80 -20 73 0 80 -2 80 -20 0 -18 7 -20 60 -20 53 0 60 -2 60 -20 0 -18 7
    -20 80 -20 73 0 80 -2 80 -20 0 -19 7 -20 100 -20 93 0 100 -1 100 -20 0 -19
    7 -20 100 -20 93 0 100 -1 100 -20 0 -19 7 -20 165 -20 158 0 165 -1 165 -20
    0 -19 7 -20 260 -20 253 0 260 -1 260 -20 0 -19 7 -20 200 -20 193 0 200 1
    200 20 0 18 7 20 60 20 53 0 60 -2 60 -20 0 -19 7 -20 205 -20 198 0 205 1
    205 20 0 20 7 20 280 20 273 0 280 0 280 20 0 19 7 20 140 20 133 0 140 1 140
    20 0 19 7 20 105 20 98 0 105 1 105 20 0 19 7 20 100 20 93 0 100 1 100 20 0
    18 7 20 80 20 73 0 80 2 80 20 0 18 7 20 80 20 73 0 80 2 80 20 0 18 7 20 60
    20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60
    2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0 18 7 20 60 20 53 0 60 2 60 20 0
    17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 45 20 38 0 45 3 45 20 0 18 7 20 60
    20 53 0 60 2 60 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40
    3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0
    17 7 20 40 20 33 0 40 3 40 20 0 17 7 20 40 20 33 0 40 3 40 20 0 13 7 20 20
    20 19 0 20 -7 20 -260 0 -253 -1 -260 -20 -260 -17 0 -20 -7 -20 -40 0 -33 -3
    -40 -20 -40 -17 0 -20 -7 -20 -40 0 -33 -3 -40 -20 -40 -13 0 -20 -7 -20 -20
    0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7
    -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13
    0 -20 -7 -20 -20 0 -13 -7 -20 -20 -20 -13 0 -20 -7 -20 -20 0 -13 -7 -20 -20
    -20 -13 0 -20 -7 -20 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -13 -7
    -20 -20 -20 -15 0 -20 -7 -20 -25 0 -22 -4 -25 -40 -25 -33 0 -40 -3 -40 -20
    0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -45 -20 -38 0 -45 -3
    -45 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33
    0 -40 -3 -40 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -18 -7 -20 -60
    -20 -53 0 -60 -2 -60 -20 0 -17 -7 -20 -40 -20 -33 0 -40 -3 -40 -20 0 -18 -7
    -20 -60 -20 -53 0 -60 -2 -60 -20 0 -18 -7 -20 -60 -20 -53 0 -60 -2 -60 -20
    0 -18 -7 -20 -80 -20 -73 0 -80 -2 -80 -20 0 -18 -7 -20 -80 -20 -73 0 -80 -2
    -80 -20 0 -19 -7 -20 -100 -20 -93 0 -100 -1 -100 -20 0 -19 -7 -20 -105 -20
    -98 0 -105 -1 -105 -20 0 -19 -7 -20 -120 -20 -113 0 -120 -1 -120 -20 0 -19
    -7 -20 -200 -20 -193 0 -200 -1 -200 -20 0 -20 -7 -20 -665 -20 -658 0 -665 0
    -665 20 0 19 -7 20 -180 20 -173 0 -180 1 -180 20 0 19 -7 20 -145 20 -138 0
    -145 1 -145 20 0 19 -7 20 -100 20 -93 0 -100 1 -100 20 0 19 -7 20 -100 20
    -93 0 -100 1 -100 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -80 20
    -73 0 -80 2 -80 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 18 -7 20 -60 20
    -53 0 -60 2 -60 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -45 20
    -38 0 -45 3 -45 20 0 18 -7 20 -60 20 -53 0 -60 2 -60 20 0 17 -7 20 -40 20
    -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 17 -7 20 -40 20
    -33 0 -40 3 -40 20 0 17 -7 20 -40 20 -33 0 -40 3 -40 20 0 13 -7 20 -20 20
    -15 0 -20 7 -20 25 0 23 -4 25 -40 25 -33 0 -40 3 -40 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 17 -7 20 -40 20
    -33 0 -40 3 -40 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -13 0 -20 7 -20 20 0 13 -7 20 -20 20 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -17 0 -20 7 -20 40 0 33 -3 40 -20 40 -13 0 -20 7 -20 20 0 13 -7 20 -20 20
    -18 0 -20 7 -20 60 0 53 -2 60 -20 60 -19 0 -20 7 -20 240 l0 240 40 0 c33 0
    40 -3 40 -20z" />

            </g>
        </svg>
    </div>
</template>
  
<script>
export default {
    name: 'CubeIcon'
};
</script>
  
<style scoped>
.icon-wrapper {
    padding: 0;
    margin: 0;
    display: inline-block;
    position: relative;
}

.icon-wrapper svg {
    padding: 0;
    margin: 0;
    display: block;
}

.icon {
    width: 100%;
    height: 100%;
    fill: orange;
    /* Set your desired color here */
}
</style>
  