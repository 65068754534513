<template lang="pug">
.info-banner(@click="$router.push('/pricing');")
    span.icon 🎉
    span.text {{ text }}
</template>
      
<script>
import moment from "moment";

export default {
    props: {
        text: String,
    },
    data() {
        return {
            isOpen: false,

        };
    },
    methods: {

    },
    mounted() {

    },
    beforeDestroy() {

    }

};
</script>
    
<style lang="scss" scoped>
.info-banner {
    display: flex;
    align-items: center;
    background-color: $xprofit;
    color: white;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
}

.info-banner:hover {
  cursor: pointer;
  /* Add any other visual feedback for hover state */
}

.icon {
    margin-right: 15px;
}

.text {
    font-weight: $big-Font-Weight;
    font-size: $medium-Font-Size;
}
</style>