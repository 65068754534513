import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import api from './modules/api'
import shopify from './modules/shopify'
import userdata from './modules/user-data'
import calculations from './modules/calculations'


Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    pageErrors: [],
    counter: 0
  },
  getters: {
  },
  mutations: {
    ChangePageError (state, errorObject) {
      errorObject.id = state.counter++;
      state.pageErrors.push(errorObject);
    },
    RemovePageError (state, id) {
      state.pageErrors = state.pageErrors.filter(x => x.id != id);
    },
    ClearAllErrors (state) {
      state.pageErrors = [];
    }
  },
  actions: {
    AddPageError(context, error) {
      context.commit('ChangePageError', error)
    },
    RemovePageError(context, id) {
      context.commit('RemovePageError', id)
    },
    ClearAllErrors(context) {
      context.commit('ClearAllErrors')
    },
  },
  modules: {
    user,
    api,
    shopify,
    userdata,
    calculations
  }
})
