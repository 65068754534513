<template lang="pug">
.xprofit-tour
  v-tour(name="xprofittour" :steps="steps" :options="myOptions" :callbacks="myCallbacks")
</template>
    
<script>
export default {
  data() {
    return {
      myCallbacks: {
        onPreviousStep: this.previousCallback,
        onNextStep: this.nextCallback,
        onSkip: this.tourSkippedCallback,
        onFinish: this.finishedCallback,
      },
      steps: [
        //         {
        //           target: '.invisbleBlocker',
        //           content: `<h1><span style="color:#BB25E8;">Welcome to X</span>Profit</h1> 

        // <p>As a dropshipper, it is essential to keep an eye on your profits. XProfit is a simple analytics tool designed specifically for dropshippers.</p>

        // <p>Let us take you on a brief tour to show you what's important and help you track everything correctly.</p>
        // `
        //         },
        {
          target: '#menu-link-general',
          content: `<h1><span style="color:#1D2752;">Setup </span><span style="color:#BB25E8;">X</span><span style="color:#1D2752;">Profit</span></h1>
          <p style="font-family: 'Gibson', sans-serif; font-size: 16px; font-weight: 400; color: #1D2752; opacity: 0.8;">
  To adjust transaction fees & more, navigate to the "Setup" tab.
</p>`,
          params: {
            placement: 'right'
          },
          before: type => new Promise((resolve, reject) => {
            // Time-consuming UI/async operation here
            this.$router.push("/settings/general")
            setTimeout(() => {
              resolve();
            }, 500);
          })
        },
        {
          target: '#marketing-integration',
          content: `<h1><span style="color:#1D2752;">Marketing </span><span style="color:#BB25E8;">Integrations</span></h1>
          <p style="font-family: 'Gibson', sans-serif; font-size: 16px; font-weight: 400; color: #1D2752; opacity: 0.8;">
            You can connect a new marketing platform at any time, and your ad spend will be automatically imported.
</p>`,
          params: {
            placement: 'bottom'
          },
          // before: type => new Promise((resolve, reject) => {
          //   // Time-consuming UI/async operation here
          //   this.$router.push("/settings/marketing")
          //   setTimeout(() => {
          //     resolve();
          //   }, 500);
          // })
        },
        {
          target: '#product-costs-integration',
          content: `<h1 style="font-family: 'Gibson', sans-serif; color: #1D2752;">
  Product Costs
</h1>
<p style="font-family: 'Gibson', sans-serif; font-size: 16px; font-weight: 400; color: #1D2752; opacity: 0.8;">
  If you use CJ, you can import your product costs. Otherwise, you can use our supplier invoice import or set them manually.
</p>
`,
          params: {
            placement: 'bottom'
          },
          // before: type => new Promise((resolve, reject) => {
          //   // Time-consuming UI/async operation here
          //   this.$router.push("/settings/marketing")
          //   setTimeout(() => {
          //     resolve();
          //   }, 500);
          // })
        },
        {
          target: '#menu-link-productcosts',
          content: `<h1><span style="color:#1D2752;">Manual & </span><span style="color:#BB25E8;">Import</span></h1>
          <p style="font-family: 'Gibson', sans-serif; font-size: 16px; font-weight: 400; color: #1D2752; opacity: 0.8;">
            Here, you can set product costs manually, in bulk, or import them using your supplier invoice. Additionally, XProfit will verify the accuracy of your invoice, ensuring you are never overcharged. 
</p>`,
          params: {
            placement: 'bottom'
          },
          before: type => new Promise((resolve, reject) => {
            // Time-consuming UI/async operation here
            this.$router.push("/settings/productcosts")
            setTimeout(() => {
              resolve();
            }, 500);
          })
        },
        {
          target: '#menu-link-dashboard',
          content: `<h1><span style="color:#1D2752;">Dashboard & </span><span style="color:#BB25E8;">Store Profit</span></h1>
          <p style="font-family: 'Gibson', sans-serif; font-size: 16px; font-weight: 400; color: #1D2752; opacity: 0.8;">
  Once you have entered the correct data, you can begin analyzing your store's profitability. Please be aware that any changes will take 4 minutes to appear.
</p>
          `,
          params: {
            placement: 'right'
          },
          before: type => new Promise((resolve, reject) => {
            // Time-consuming UI/async operation here
            this.$router.push("/")
            setTimeout(() => {
              resolve();
            }, 500);
          })

        },
        {
          target: '#menu-link-analytics',
          content: `<h1><span style="color:#1D2752;">Product </span><span style="color:#BB25E8;">Analytics</span></h1>
          <p style="font-family: 'Gibson', sans-serif; font-size: 16px; font-weight: 400; color: #1D2752; opacity: 0.8;">
    In Product Analytics, you will be able to view the exact profitability, refund rate, and more for each product, enabling you to make precise decisions.
  <br><br>
  You can access this feature under the tab “Product Analytics”.
</p>`,
          params: {
            placement: 'right'
          },
          before: type => new Promise((resolve, reject) => {
            // Time-consuming UI/async operation here
            this.$router.push("/analytics")
            setTimeout(() => {
              resolve();
            }, 500);
          })

        },

      ],
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish'
        },
        highlight: true
      },
    }
  },

  methods: {
    previousCallback(currentStep) {
      this.$store.dispatch("user/SetTourStep", currentStep)
    },
    nextCallback(currentStep) {
      this.$store.dispatch("user/SetTourStep", currentStep)
    },
    tourSkippedCallback(currentStep) {
      this.$store.dispatch("user/SetTourStep", -999)
    },
    finishedCallback(step) {
      this.$store.dispatch("user/SetTourStep", -999)
    },
    startTour() {
      var _this = this;
      setTimeout(() => {
        _this.$tours.xprofittour.start()
      }, 500);
    },
  },
}

</script>

<style scoped lang="scss">
::v-deep .v-step {
  background-color: white !important;
  max-width: 420px !important;
  border-radius: 10px !important;
  color: black !important;
  font-size: 14px;
  font-weight: 100;
  padding: 20px;
}

::v-deep .v-step__button {
  background-color: rgb(245, 245, 248) !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
  color: #1D2752 !important;
  border: none !important;
  font-size: 16px !important;
  padding: 5px 10px !important;
  margin-left: 10px !important;
  height: 40px !important;

  &:hover {
    background-color: hsla(0, 0%, 0%, .2) !important;
  }
}

::v-deep .v-step__arrow {
  display: none;
}
</style>